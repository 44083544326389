import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material'
import {
  SimpleForm,
  useRecordContext,
  useRefresh,
  useNotify,
  useDataProvider,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  useGetIdentity,
  Button,
} from 'react-admin'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import AddUserIcon from '@mui/icons-material/PersonAddOutlined'
import RemoveUserIcon from '@mui/icons-material/PersonRemoveOutlined'

export const RequestAssignmentAccordion: React.FC = () => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const { identity } = useGetIdentity()

  const handleAssigneesChange = async (assigneeIds: string[]) => {
    try {
      await dataProvider.customRequest('requestServices', record.id, {
        data: { assigneeIds },
        method: 'PUT',
      })
      notify('Assignees updated')
      refresh()
    } catch (e: any) {
      notify(`Could not update assignees: ${e.message}`, { type: 'error' })
    }
  }

  const handleAssigneesToggleMe = async () => {
    if (identity?.id && record?.assigneeIds) {
      let newAssigneeIds = record.assigneeIds

      if (!record.assigneeIds?.includes(identity.id)) {
        newAssigneeIds.push(identity.id)
      } else {
        newAssigneeIds = newAssigneeIds.filter((a: string) => a !== identity.id)
      }

      return handleAssigneesChange(newAssigneeIds)
    }
  }

  if (!record) return null

  return (
    <Accordion expanded={true} disableGutters>
      <AccordionSummary>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" alignItems="center" display="flex" gap={1}>
              {record?.assigneeIds?.length > 0 ? (
                <DoneIcon sx={{ mr: 1 }} />
              ) : (
                <ToDoIcon sx={{ mr: 1 }} />
              )}
              Request: Assignees
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <SimpleForm
          toolbar={
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  size="small"
                  label={
                    record?.assigneeIds.includes(identity?.id)
                      ? 'De-assign me'
                      : 'Assign me'
                  }
                  type="button"
                  variant="outlined"
                  color="info"
                  disabled={!record || !identity}
                  onClick={handleAssigneesToggleMe}
                  startIcon={
                    !record?.assigneeIds.includes(identity?.id) ? (
                      <AddUserIcon />
                    ) : (
                      <RemoveUserIcon />
                    )
                  }
                />
              </Grid>
            </Grid>
          }
          sx={{
            ':last-child': { padding: 0 },
          }}
        >
          <ReferenceArrayInput
            source="assigneeIds"
            reference="users/list"
            sx={{ margin: 0 }}
            filter={{
              role: {
                in: [
                  'gnx-procurement',
                  'gnx-sales',
                  'gnx-admin',
                  'admin',
                  'sales',
                  'procurement',
                ],
              },
            }}
          >
            <AutocompleteArrayInput
              variant="outlined"
              fullWidth
              label="Assignee(s)"
              optionText="name"
              optionValue="id"
              onChange={handleAssigneesChange}
            />
          </ReferenceArrayInput>
          <Typography variant="caption" color="gray">
            Assignees are the people (both procurement and sales, anyone really)
            actively working on this request. They will be kept in the loop with
            any subsequent changes.
          </Typography>
        </SimpleForm>
      </AccordionDetails>
    </Accordion>
  )
}
