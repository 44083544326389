import React from 'react'
import { Grid, Typography } from '@mui/material'
import {
  Button,
  SimpleForm,
  required,
  useCreate,
  useRecordContext,
} from 'react-admin'
import { useFormState } from 'react-hook-form'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SendIcon from '@mui/icons-material/SendOutlined'
import { ProductSalesMarginFormToolbarProps } from './ProductSalesMarginFormToolbar.types'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'
import { MarkdownInput } from '@react-admin/ra-markdown'
import { NotesDialogToolbar } from '../../atoms/NotesDialogToolbar'
import SaveIcon from '@mui/icons-material/SaveOutlined'

export const ProductSalesMarginFormToolbar: React.FC<
  ProductSalesMarginFormToolbarProps
> = (props) => {
  const { isDirty } = useFormState()
  const { record } = useRecordContext()
  const [create] = useCreate()

  const submitForm = async (data: object) => {
    await create('notes', { data })
    props.onConfirm()
  }

  const confirmDisabled = !record?.isOfferConfirmed || record?.isQuoteConfirmed
  const sendDisabled =
    !record?.isQuoteConfirmed ||
    !record?.quotes?.length ||
    isDirty ||
    record?.isQuoteSent

  return !record?.isQuoteSent ? (
    <Grid container spacing={1} justifyContent="flex-end">
      {!confirmDisabled && (
        <>
          <Grid item>
            <Button
              size="small"
              label="Save"
              type="button"
              variant="outlined"
              color="info"
              onClick={props.onSave}
              startIcon={<SaveIcon />}
            />
          </Grid>
          <Grid item>
            <CreateInDialogButton
              title={<Typography variant="h6">Add Note</Typography>}
              ButtonProps={{
                size: 'small',
                type: 'button',
                variant: 'contained',
                color: 'info',
              }}
              label="Confirm"
              icon={<CheckCircleIcon />}
              fullWidth
              maxWidth="md"
            >
              <SimpleForm
                defaultValues={{
                  isInternal: true,
                  relatedName: 'RequestProduct',
                  relatedId: record?.id,
                  clientId: record.request?.clientId,
                }}
                toolbar={<NotesDialogToolbar />}
                sx={{ paddingY: 0 }}
                onSubmit={submitForm}
              >
                <MarkdownInput
                  height="200px"
                  fullWidth
                  label={false}
                  source="content"
                  validate={required()}
                />
              </SimpleForm>
            </CreateInDialogButton>
          </Grid>
        </>
      )}
      <Grid item>
        <Button
          size="small"
          label="Send"
          type="button"
          onClick={props.onSend}
          variant="contained"
          color="primary"
          disabled={sendDisabled}
          startIcon={<SendIcon />}
        />
      </Grid>
    </Grid>
  ) : null
}
