import React from 'react'
import { PageTitle } from '../../atoms/PageTitle'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  BooleanField,
} from 'react-admin'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'

/**
 * Show a document category.
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * <DocumentCategoryShow />
 * @see {@link https://marmelab.com/react-admin/Show.html|Show}
 * @see {@link https://marmelab.com/react-admin/Inputs.html|Inputs}
 */
export const DocumentCategoryShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Document category" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="key" />
          <TextField source="name" />
          <BooleanField source="isInternal" />
        </Tab>
        <Tab label={'history'}>
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
