import {
  DeleteWithConfirmButton,
  TopToolbar,
  useRecordContext,
  useUpdate,
  Button,
  Confirm,
  useNotify,
  useRefresh,
} from 'react-admin'
import { Typography } from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'
import CancelIcon from '@mui/icons-material/Cancel'
import { useState } from 'react'

interface RequestProductToolbarProps {
  isCustomProduct?: boolean
  onDeleted?: (data?: any) => void
}

export const RequestProductToolbar: React.FC<RequestProductToolbarProps> = (
  props
) => {
  const record = useRecordContext()
  const [open, setOpen] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()
  const [update, { isLoading }] = useUpdate()

  if (!record) return null

  const toggleCancel = record?.isCancelled ? 'uncancel' : 'cancel'

  const isDeleteDisabled =
    !props?.isCustomProduct ||
    record?.vendorOffers?.length > 0 ||
    record?.quotes?.length > 0
  const isCancelDisabled = !isDeleteDisabled || record?.isQuoteSent

  const handleCancel = () => {
    update(
      `requestProducts`,
      {
        id: `${record.id}/${toggleCancel}`,
        data: { id: record.id },
        previousData: record,
      },
      {
        onSuccess: () => {
          notify(`Product ${toggleCancel}led successfully`, { type: 'info' })
          refresh()
        },
        onError: (error) => {
          const typedError = error as { message?: string }
          notify(
            `Error cancelling product: ${
              typedError.message || 'Unknown error'
            }`,
            { type: 'error' }
          )
        },
      }
    )
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <TopToolbar sx={{ my: 1 }}>
      <Typography
        color="text.secondary"
        sx={{ width: '100%' }}
        variant="subtitle1"
      >
        Product: {record?.name ?? 'Custom (new)'}
      </Typography>
      <Button
        onClick={handleOpen}
        disabled={isCancelDisabled || isLoading}
        startIcon={<CancelIcon />}
        color="secondary"
        size="large"
        label={toggleCancel}
      />

      <Confirm
        isOpen={open}
        title="Confirm Cancellation"
        content="Are you sure you want to cancel this product?"
        onConfirm={handleCancel}
        onClose={handleClose}
      />

      <DeleteWithConfirmButton
        resource={`requestServices/${record.requestServiceId}/customProducts`}
        mutationMode="undoable"
        confirmTitle="Confirm Deletion"
        confirmContent="Are you sure you want to delete this custom product? This action cannot be undone."
        disabled={isDeleteDisabled}
        size="large"
        color="primary"
        icon={<DeleteIcon />}
        redirect={`/quote-requests/${record.requestId}/services/${record.requestServiceId}`}
        mutationOptions={{
          onSettled: (data, error, _variables, _context) => {
            if (!error && props.onDeleted) props.onDeleted(data)
          },
        }}
      />
    </TopToolbar>
  )
}
