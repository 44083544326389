import React from 'react'
import {
  Create,
  CreateProps,
  TextInput,
  required,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  regex,
  FileInput,
  FileField,
  SelectInput,
  BooleanInput,
} from 'react-admin'
import { MetaListInput } from '../../organisms/MetaList'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Page to create a new document.
 * @param {CreateProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <DocumentCreate {...props} />
 * )
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|Create}
 */
export const DocumentCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="general">
          <ReferenceAutocomplete
            required
            label="Category"
            source="categoryId"
            reference="documentCategories/list"
          />
          <TextInput
            source="documentNo"
            fullWidth
            label="Document no. (leave empty to auto generate)"
            validate={[regex(/^[A-Z]{3}[0-9]{5,12}$/)]}
          />
          <TextInput source="title" validate={required()} fullWidth />
          <SelectInput
            source="state"
            defaultValue="draft"
            validate={required()}
            choices={[
              { id: 'draft', name: 'Draft' },
              { id: 'pending', name: 'Pending' },
              { id: 'awaiting-client', name: 'Awaiting Client' },
              { id: 'final', name: 'Final' },
            ]}
            fullWidth
          />
          <ReferenceAutocomplete
            label="Client"
            source="clientId"
            reference="clients/list"
          />
          <ReferenceAutocomplete
            label="Vendor"
            source="vendorId"
            reference="vendors/list"
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.clientId && (
                <ReferenceAutocomplete
                  label="Service"
                  source="serviceId"
                  reference="services/list"
                  filter={{
                    clientId: formData.clientId,
                    vendorId: formData.vendorId,
                  }}
                  disableSyncWithLocation
                />
              )
            }
          </FormDataConsumer>
          <BooleanInput
            source="isInternal"
            label="Is internal (invisible for customer)"
          />
          <FileInput source="file" label="Upload file" multiple={true}>
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
        <FormTab label="meta" path="meta">
          <MetaListInput source="meta" />
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
