import React from 'react'
import { PageTitle } from '../../atoms/PageTitle'
import { useJiraIdValidate } from './User.hooks'
import { DualSelect } from '../../atoms/DualSelect'
import {
  Edit,
  EditProps,
  TextInput,
  required,
  BooleanInput,
  PasswordInput,
  TabbedForm,
  FormTab,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Page to edit a user.
 * @param props The props passed to the component.
 * @returns {JSX.Element} The JSX to render.
 * @example
 * <UserEdit />
 * @see https://marmelab.com/react-admin/CreateEdit.html
 * @see https://marmelab.com/react-admin/CreateEdit.html#the-createedit-component
 * @see https://marmelab.com/react-admin/CreateEdit.html#the-tabbedform-component
 */
export const UserEdit: React.FC<EditProps> = (props) => {
  const jiraIdValidate = useJiraIdValidate()

  return (
    <Edit
      {...props}
      title={<PageTitle prefix="User" noRecordValue="create" />}
      mutationMode="undoable"
    >
      <TabbedForm>
        <FormTab label="general" key="general">
          <TextInput source="name" validate={required()} fullWidth />
          <TextInput source="email" disabled fullWidth />
          <ReferenceAutocomplete
            required
            source="role"
            reference="userRoles/list"
          />
          <BooleanInput source="isActive" fullWidth />
          <PasswordInput
            source="password"
            label={'Change password'}
            fullWidth
          />
          <BooleanInput
            source="sendPasswordRecoveryMail"
            defaultValue={false}
            fullWidth
          />
          <BooleanInput
            source="removeTwoFactorAuth"
            defaultValue={false}
            fullWidth
          />
        </FormTab>
        <FormTab label="clients" key="clients">
          <DualSelect source="clientIds" reference="clients/list" />
        </FormTab>
        <FormTab label="groups" key="groups">
          <DualSelect source="userGroupIds" reference="userGroups/list" />
        </FormTab>
        <FormTab label="permissions" key="permissions">
          <DualSelect
            label="Permissions"
            source="permissionIds"
            reference="permissions/list"
          />
        </FormTab>
        <FormTab label="preferences" key="preferences">
          <BooleanInput
            source="preferences.receiveDailyDigests"
            label={'Receive daily digest mails'}
            defaultValue={false}
          />
          <BooleanInput
            source="preferences.receiveRequestAlerts"
            label={'Receive alerts for new requests'}
            defaultValue={false}
          />
          <BooleanInput
            source="preferences.receiveQuoteAlerts"
            label={'Receive alerts for quote status updates'}
            defaultValue={false}
          />
          <BooleanInput
            source="preferences.receiveOnboardingAlerts"
            label={'Receive alerts for on-boardings awaiting completion'}
            defaultValue={false}
          />
          <BooleanInput
            source="preferences.receiveWeeklyServiceOverviews"
            label={'Receive weekly service overview mails'}
            defaultValue={false}
          />
          <BooleanInput
            source="preferences.showLmpsOnSearchResults"
            label={'Show LMPs on search results'}
            defaultValue={false}
          />
          <BooleanInput
            source="preferences.showIspsOnSearchResults"
            label={'Show ISPs on search results'}
            defaultValue={false}
          />
        </FormTab>
        <FormTab label="advanced" key="advanced">
          <TextInput
            source="jiraId"
            validate={jiraIdValidate}
            label="Jira user ID"
            fullWidth
          />
          <BooleanInput source="syncJira" fullWidth />
          <TextInput source="slackId" label="Slack user ID" fullWidth />
          <BooleanInput
            source="cleanDemoAccount"
            defaultValue={false}
            fullWidth
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
