import React from 'react'
import { SearchFilter } from '../../atoms/SearchFilter'
import { List, ListProps, Datagrid, TextField, BooleanField } from 'react-admin'

/**
 * List all document categories.
 * @property {ListProps} props
 * @returns {React.FC<ListProps>}
 * @example
 * <DocumentCategoryList />
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 * @see {@link https://marmelab.com/react-admin/Inputs.html|Inputs}
 */
export const DocumentCategoryList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<SearchFilter />}
      sort={{ field: 'key', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="key" fullWidth />
        <TextField source="name" fullWidth />
        <BooleanField source="isInternal" />
      </Datagrid>
    </List>
  )
}
