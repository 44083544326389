import {
  AutocompleteArrayInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin'

/**
 * Filters for the document list page.
 * @returns {Array} An array of react-admin filters.
 */
export const useDocumentFilters = () => {
  return [
    <TextInput label="Search" source="term" alwaysOn />,
    <SelectInput
      source="state"
      alwaysOn
      choices={[
        { id: 'draft', name: 'Draft' },
        { id: 'pending', name: 'Pending' },
        { id: 'awaiting-client', name: 'Awaiting Client' },
        { id: 'final', name: 'Final' },
      ]}
    />,
    <ReferenceInput source="clientId" reference="clients/list">
      <AutocompleteArrayInput label={'Client'} optionText="name" />
    </ReferenceInput>,
    <ReferenceInput source="categoryId" reference="documentCategories/list">
      <AutocompleteArrayInput label={'Category'} optionText="name" />
    </ReferenceInput>,
    <BooleanInput source="isInternal" />,
  ]
}
