import React from 'react'
import {
  Show,
  ShowProps,
  Datagrid,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  NumberField,
  ShowButton,
  SimpleShowLayout,
} from 'react-admin'
import { MutationsList } from '../../organisms/MutationsList'
import { MetaListDisplay } from '../../organisms/MetaList'
import { CurrencyField } from '../../atoms/CurrencyField'
import { TextPreSuffixField } from '../../atoms/TextPreSuffixField'
import { ReferenceManyTab } from '../../atoms/FormTab'
import { TypeStateField } from '../../atoms/TypeStateField'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ReferenceText } from '../../molecules/ReferenceText'

/**
 * Page to show a single Vendor Contract
 * @param props
 * @returns {JSX.Element}
 * @example
 * import { VendorContractShow } from './VendorContractShow'
 * <VendorContractShow {...props} />
 * @todo general tab shares a common design with other pages,
 * should be refactored into a component
 */
export const VendorContractShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Vendor Contract" />}>
      <TabbedShowLayout {...props}>
        <Tab label="general" key="general">
          <SimpleShowLayout
            sx={{
              '& .RaSimpleShowLayout-stack': {
                md: {
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                  gridGap: '4px',
                  gridAutoColumns: '1fr',
                  alignSelf: 'center',
                  '& .ra-field': {
                    marginTop: '8px',
                  },
                },
              },
              width: '100%',
              margin: 0,
              padding: 0,
            }}
          >
            <ReferenceChip
              referenceSource="vendorId"
              tooltipSource="vendorNo"
              source="name"
              label={'Vendor'}
              reference="vendors"
              emptyText="N/A"
              link="show"
              chipSource="name"
              removeDoubleLabel
            />
            <ReferenceChip
              referenceSource="serviceId"
              tooltipSource="serviceNo"
              source="name"
              label={'Service'}
              reference="services"
              emptyText="N/A"
              link="show"
              chipSource="name"
              removeDoubleLabel
            />
            <ReferenceChip
              referenceSource="vendorOfferId"
              tooltipSource="offerNo"
              source="offerNo"
              label={'Vendor Offer'}
              reference="vendorOffers"
              emptyText="N/A"
              link="show"
              chipSource="offerNo"
              removeDoubleLabel
            />
            <TextField source="contractNo" emptyText="N/A" fullWidth />
            <TypeStateField source="state" emptyText="N/A" />
            <TextField source="reference" emptyText="N/A" fullWidth />
            <TextField source="description" emptyText="N/A" fullWidth />
            <NumberField source="contractPeriod" emptyText="N/A" fullWidth />
            <NumberField source="renewalPeriod" emptyText="N/A" fullWidth />
            <NumberField
              source="cancellationPeriod"
              emptyText="N/A"
              fullWidth
            />
            <DateField source="periodStart" emptyText="N/A" fullWidth />
            <DateField source="periodEnd" emptyText="N/A" fullWidth />
            <TextField source="billingCycle" emptyText="N/A" fullWidth />
            <ReferenceText
              source={'purchaseCurrency'}
              label={'Purchase Currency'}
              reference="currencies/list"
              emptyText={'N/A'}
              link={false}
            />
            <CurrencyField
              source="purchaseNrc"
              currency={'purchaseCurrency'}
              label="Purchase NRC"
            />
            <CurrencyField
              source="purchaseMrc"
              currency={'purchaseCurrency'}
              label="Purchase MRC"
            />
            <TextPreSuffixField
              source="purchaseVat"
              label="Purchase VAT"
              suffix={'%'}
            />
            <TextPreSuffixField
              source="serviceUptime"
              suffix="%"
              label="Uptime SLA"
              emptyText="Best-effort"
            />
            <TextPreSuffixField
              source="leadTime"
              label="Lead time"
              suffix={' weeks'}
            />
            <TextPreSuffixField
              source="meanTimeToRepair"
              label="MTTR"
              suffix=" hours"
              emptyText="Best-effort"
            />
            <TextField source="supportLevel" emptyText="N/A" />
            <DateField source="updatedAt" showTime />
            <DateField source="createdAt" showTime />
          </SimpleShowLayout>
        </Tab>
        <ReferenceManyTab
          reference="documents"
          target="relatedId"
          label="documents"
        >
          <Datagrid
            sx={{
              width: '100%',
              '& .column-title': {
                maxWidth: '20em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
          >
            <TextField source="documentNo" fullWidth />
            <TextField source="title" fullWidth />
            <DateField source="createdAt" showTime />
            <ShowButton />
          </Datagrid>
        </ReferenceManyTab>
        <Tab label={'meta'} key="meta">
          <MetaListDisplay source="meta" />
        </Tab>
        <Tab label={'history'} key="history">
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
