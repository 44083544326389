import React from 'react'
import { IReferenceMany } from './FormTab.types'
import {
  Pagination,
  useGetManyReference,
  useRecordContext,
  ReferenceManyField,
  TabbedForm,
} from 'react-admin'
import { FormTabLabel } from './FormTabLabel.component'

/**
 * Represents a form tab with a reference many field.
 * If the reference or target props are undefined, the component will not be rendered.
 * @param props - The component props.
 * @returns The rendered FormTab component.
 * @see IReferenceMany
 * @see FormTab
 * @see ReferenceManyField
 * @see useGetManyReference
 * @see useRecordContext
 * @see FormTabLabel
 * @see Pagination
 * @see https://marmelab.com/react-admin/Inputs.html#using-a-custom-input-component
 * @see https://mui.com/components/tabs/#form-tabs
 * @see https://mui.com/components/pagination/#pagination
 * @see https://mui.com/components/chips/#chip
 */
export const ReferenceManyFormTab: React.FC<IReferenceMany> = (props) => {
  const record = useRecordContext()

  let { target, ...rest } = props

  const { isLoading, total } = useGetManyReference(
    props.reference,
    {
      target: target,
      id: record?.id,
      pagination: props.pagination || { page: 1, perPage: 25 },
      sort: { field: 'createdAt', order: 'DESC' },
    },
    {
      enabled: !!record,
    }
  )

  if (!props.reference || !props) {
    return null
  }

  return (
    <TabbedForm.Tab
      {...rest}
      label={
        <FormTabLabel
          loading={isLoading || total === undefined}
          label={props.label || props.reference}
          count={total}
        />
      }
    >
      <ReferenceManyField {...props} pagination={<Pagination />}>
        {props.children}
      </ReferenceManyField>
    </TabbedForm.Tab>
  )
}
